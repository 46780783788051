import { PDFDocument } from "pdf-lib";

export const fobiddenInk1 =
  "It is strictly forbidden to use any other ink than the one provided by MGI Digital Technology. Any damage incurred to the equipment due to";
export const fobiddenInk2 =
  "the use of another ink will immediately void the manufacturer’s warranty and the user will be held responsible for all damages.";
export const fobiddenVarnish1 =
  "It is strictly forbidden to use any other varnish than the one provided by MGI Digital Technology. Any damage incurred to the equipment due to";
export const fobiddenVarnish2 =
  "the use of another varnish will immediately void the manufacturer’s warranty and the user will be held responsible for all damages.";
export const fobiddenPrimer1 =
  "It is strictly forbidden to use any other primer than the one provided by MGI Digital Technology. Any damage incurred to the equipment due to";
export const fobiddenPrimer2 =
  "the use of another primer will immediately void the manufacturer’s warranty and the user will be held responsible for all damages.";

export const defaultRisks = [
  "H315 Causes skin irritation",
  "H319 Causes serious eye irritation",
  "H317 May cause an allergic skin reaction",
  "H361d Suspected of damaging the unborn child",
  "H335 May cause respiratory irritation",
  "H411 Toxic to aquatic life with long lasting effects",
];

export const defaultText = `
Wear protective gloves / protective clothing / eye protection / face protection.\n
Store in a well-ventilated place. Keep container tightly closed.\n
Dispose of contents / container in accordance with local / national / international regulations.\n
If on skin: Wash with plenty of water.\n
If skin irritation occurs: Get medical advice/attention.\n
If in eyes: Rinse cautiously with water for several minutes. Remove contact lenses if present and easy to do.\n
Continue rinsing.\n
If inhaled: Remove person to fresh air and keep comfortable for breathing.\n
If eye irritation persists: Get medical advice/attention.\n`;

// Return true if the PN doesn't have a bar code
export function noBarCodeLabel(pn: string) {
  return (
    pn === "8484S" ||
    pn === "18567" ||
    pn === "12985S" ||
    pn === "15604S" ||
    pn === "15605S" ||
    pn === "15606S" ||
    pn === "15607S" ||
    pn === "16967S" ||
    pn === "19077S" ||
    pn === "18508S" ||
    pn === "16968S" ||
    pn === "19163S" ||
    pn === "17492" ||
    pn === "17493" ||
    pn === "17501S" ||
    pn === "17502S" ||
    pn === "17503S" ||
    pn === "17504S" ||
    pn === "18431S"
  );
}

export async function defineImageFromUrl(
  url: string,
  pdfDoc: PDFDocument,
  isPng: boolean,
  scale?: number
) {
  const pngImageBytes =
    url && (await fetch(url).then((res) => res.arrayBuffer()));

  const result = isPng
    ? await pdfDoc.embedPng(pngImageBytes)
    : await pdfDoc.embedJpg(pngImageBytes);

  const dimension = scale !== undefined ? result.scale(scale) : undefined;

  return { image: result, dimension: dimension };
}
